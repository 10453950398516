.register-wrapper {
    display: flex;
    justify-content: center;
}

.register{
    width: 374px;
    height: 450px;
    display: flex;
    align-self: center;
    justify-content: center;
    flex-direction: column;
    background-color: #2E2E2E;
    padding: 30px;
    border-radius: 18px;
}

.title{
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.46px;
    margin-bottom: 30px;
}

.role-select {
    position: relative;
    display: inline-block;
}

select {
    appearance: none; /* Remove default arrow in some browsers */
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    height: 48px;
    padding: 10px;
    padding-left: 46px;
    background-color: #1B1B1B;
    border: none;
    color: #FFF;
    border-radius: 12px;
    outline: none;
    cursor: pointer;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.15px;
    margin-top: 20px;
}

.role {
    position: relative;
    display: inline-block;
}

.role img {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 12px;
    top: 30px;
    z-index: 1;
}

.username-register {
    position: relative;
    display: inline-block;
}

.username-register input[type="username"] {
    height: 48px;
    width: 328px;
    border-radius: 12px;
    background: #1B1B1B;
    margin-bottom: 22px;
    padding-left: 46px;
    border: none;

}
.usernameInput{
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.15px;
    

}
.usernameInput::placeholder{
    color: var(--neutral-shade-dark-0440, rgba(111, 118, 126, 0.40));
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.15px;
}
.username-register img {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 12px;
    top: 12px;
}

.password-register{
    position: relative;
    display: inline-block;
}
.password-register .icon1 {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 12px;
    top: 12px;
}
.password-register .icon2 {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 12px;
    top: 12px;
}

.password-register .icon2:hover {
    cursor: pointer;
}

.password-register input[type="password"]{
    height: 48px;
    width: 328px;
    border-radius: 12px;
    background: #1B1B1B;
    padding-left: 46px;
    border: none;
}

.password-register input[type="text"] {
    height: 48px;
    width: 328px;
    border-radius: 12px;
    background: #1B1B1B;
    padding-left: 46px;
    border: none;
}

.continue-button-register{
    position: flex;
    display: inline-block;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.15px;
    width: 374px;
    height: 48px;
    border-radius: 12px;
    background: rgba(231, 204, 151, 0.50);
    color: rgba(252, 252, 252, 0.75);
    outline:none;
    border: none;
    margin-bottom: 16px;
    cursor: pointer;
    text-decoration: none;
}
.continue-button-register a{
    position: absolute;
    text-align: center;

}

.incomplete-error {
    color: #E35C5C;
    margin-top: 8px;
}

.success-label {
    color: #64bf6a;
    margin-top: 8px;
}

@media (max-width: 470px) {
    .register {
        height: 100vh;
        width: 100vw;
        background-color: unset;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: unset;
    }

    .username-register, .password-register, select, .continue-button-register, .captcha {
        width: 84vw;
    }

    .title {
        width: 90vw;
        font-size: 32px;
    }

    .password-register input[type="password"], .username-register input[type="username"] {
        width: 280px;
    }

}