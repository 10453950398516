.header-assign-container {
    margin-top: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.title-label {
    font-family: 'Inter', sans-serif;
    vertical-align: middle;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: white;
    margin: 40px;
}

.input-assign-container {
    margin-top: 20px;
}

.input-assign-container input {
    font-family: 'Inter', sans-serif;
    margin-left: 40px;
    margin-top: 10px;
    vertical-align: middle;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: white;
    background-color: transparent;
    border: 1px solid #676767;
    border-radius: 14px;
    padding: 5px;
}

.percentage-input {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    width: 20%;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: white;
    background-color: transparent;
    border: 1px solid #676767;
    border-radius: 12px;
    padding: 10px;
}

.percentage-table {
    margin-top: 20px;
}

.btn-confirm-percentage {
    background-color: transparent;
    border: 1px solid #676767;
    width: 50%;
    margin: auto;
    cursor: pointer;
}

.btn-confirm-percentage:hover {
    background-color: #E7CC97;
    color: black;
    font-weight: 600;
}

.btn-close-percentage {
    background-color: transparent;
    border: 1px solid #676767;
    cursor: pointer;
    width: 150px;
    margin: 40px;
}

.btn-close-percentage:hover {
    background-color: #E7CC97;
    color: black;
    font-weight: 600;
}


@media (max-width: 470px) {

    .modal-content-percentage {
        width : 85vw !important;
    }
    
    .btn-confirm-percentage {
        width: 100%;
    }

    .title-label {
        font-size: 24px;
    }
}