.profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-box {
    width: 558px;
    height: 545px;
    background-color: #2E2E2E;
    border-radius: 18px;
}

.profile-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 45px;
}

.profile-title {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #FFF;
    font-size: 36px;
    margin-bottom: 30px;
}

.input-file-container {
    position: relative;
    width: 180px; /* Ajusta el ancho según tus necesidades */
    height: 180px; /* Ajusta la altura según tus necesidades */
    overflow: hidden;
  }
  
  .file-label {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .file-image {
    width: 100px;
    height: 100px;
    background-color: #101010;
    padding: 40px;
    border-radius: 100px;
    object-fit: cover;
  }

  .img-selected {
    width: 180px;
    height: 180px;
    padding: unset;
  }
  
  .small-image {
    width: 26px; /* Ajusta el tamaño según tus necesidades */
    height: 26px; /* Ajusta el tamaño según tus necesidades */
    position: absolute;
    bottom: 5px; /* Ajusta la posición vertical según tus necesidades */
    right: 5px; /* Ajusta la posición horizontal según tus necesidades */
    border-radius: 50%;
    object-fit: cover;
    background-color: #E7CC97;
    padding: 10px;
  }
  
.hidden-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.profile-username-label {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #FFF;
    font-size: 20px;
}

.profile-ig-label {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #525252;
    font-size: 16px;
    margin-top: 5px;
}

.profile-rol-box {
    border: 1px solid #EFEFEF;
    width: 150px;
    height: 44px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
}

.profile-role-label {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #FFF;
    font-size: 14px;
}

.profile-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #101010;
    background-color: #E7CC97;
    cursor: pointer;
}

.profile-lock-icon {
    width: 24px;
    height: 24px;
}

.change-password-input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.change-password-input-label {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #525252;
    font-size: 14px;
    width: 70%;
    margin-bottom: 5px;
}

.change-password-input {
    background-color: transparent;
    border: 1px solid #525252;
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #FFF;
    padding: 10px;
    font-size: 14px;
    border-radius: 8px;
    width: 70%;
}

.change-password-error-container {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.change-password-error-label {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #B83232;
    width: 70%;
    text-align: left;
    font-size: 14px;
}

.change-password-button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.change-password-button {
    cursor: pointer;
}

.success-container {
    display: flex;
    justify-content: center;
    text-align: center;
}

.success-label {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #83BF6E;
    width: 70%;
    font-size: 14px;
    margin-top: unset;
}

.modal-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
}

.modal-close {
    background-color: transparent;
    border: 1px solid #525252;
    width: 120px;
    cursor: pointer;
}

.modal-close:hover {
    background-color: #525252;
    border: 1px solid #101010;
    width: 120px;
    cursor: pointer;
    color: white;
}

@media (max-width: 470px) {
    .profile-box {
        width: 90vw;
        margin-top: 40px;
    }

    .modal-content-profile {
        width: 90vw !important;
        height: 75vh !important;
    }

    .title-change-profile {
        font-size: 20px;
    }

    .modal-close {
        font-size: 10px;
        width: 80px;
        height: 40px;
        margin-top: 25px;
    }
    
    .change-password-button {
        width: 75%;
    }
}