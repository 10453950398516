.campaign-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.campaign-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: #222222;
    width: 559px;
    height: 560px;
    border-radius: 18px;
    border: 1px solid #2E2E2E;

    overflow-y: auto;
}

.campaign-title {
    color: #FFF;
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
}

.input-div-campaign {
    margin-bottom: 30px;
}

.input-div-campaign p {
    color: #FFF;
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
}

.input-div-campaign input, textarea{
    margin-top: 5px;
    background-color: #1B1B1B;
    border: 1px solid #2E2E2E;
    border-radius: 12px;
    color: #FFF;
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    padding: 14px;
    width: 475px;
}

textarea {
    height: 160px;
    resize: none;
}

.btn-campaign {
    background-color: #E7CC97;
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #1B1B1B;
    font-weight: bold;
    cursor: pointer;
}

.cp {
    margin-left: 10px;
}

.no-results-container {
    display: flex;
    width: 75vw;
    position: absolute;
}

.no-results-msg {
    margin-top: 30vh;
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #4F4F4F;
    font-weight: bold;
    text-align: center;
    width: 100%;
}

.mtc {
    color: #8484FF;
    font-style: italic;
    cursor: pointer;
}

.modal-container-campaigns {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    max-height: 400px;
    height: 400px;
}

.links-label-title {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    margin-top: 40px;
    color: #FFF;
}

.link-list {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #FFF;
    margin-top: 50px;
    width: 380px;
    margin-left: 20px;
}

.container-header-modal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 360px;
}

.btn-close-modal-ca {
    width: 80px;
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #FFF;
    margin-top: 30px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #4F4F4F;
}

@media (max-width: 470px) {
    .campaign-container {
        margin-top: 140px;
    }

    .campaign-form {
        width: 90vw;
    }

    .input-div-campaign {
        width: 75vw;
    }

    textarea {
        max-width: 70vw;
    }
    
    .no-results-container {
        width: 300px;
    }

    .no-results-msg {
        font-size: 15px;
    }
}