*{
    margin:0px;
    padding:0px;
}

@media screen and (min-width: 768px) {
.general {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin: auto;
    width: 1440px;
    height: 1024px;
    background-color: #101010;
    }

}   
    .imagen-login-div { 
        grid-area: 1 / 1 / 2 / 2;
        position: relative;
    }
    .div2 { 
        grid-area: 1 / 2 / 2 / 3;
        position: relative; 
    }



.img-login{
    position: absolute;
    inset: 0;
    margin: auto;
    border-radius: 46px;
    
}

.login{
    width: 374px;
    height: 394px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.titulo{
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.46px;
    margin-bottom: 30px;
}
.username {
    position: relative;
    display: inline-block;
}

.username input[type="username"] {
    height: 48px;
    width: 328px;
    border-radius: 12px;
    background: #1B1B1B;
    margin-bottom: 22px;
    padding-left: 46px;
    border: none;

}
.usernameInput{
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.15px;
    

}
.usernameInput::placeholder{
    color: var(--neutral-shade-dark-0440, rgba(111, 118, 126, 0.40));
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.15px;
}
.username img {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 12px;
    top: 12px;
}
.password{
    position: relative;
    display: inline-block;
}
.password .icon1 {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 12px;
    top: 12px;
}
.password .icon2 {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 12px;
    top: 12px;
}

.password .icon2:hover {
    cursor: pointer;
}

.password input[type="password"]{
    height: 48px;
    width: 328px;
    border-radius: 12px;
    background: #1B1B1B;
    padding-left: 46px;
    border: none;
}

.password input[type="text"] {
    height: 48px;
    width: 328px;
    border-radius: 12px;
    background: #1B1B1B;
    padding-left: 46px;
    border: none;
}

.password .error_label {
    color: #E35C5C;
}

input:focus{
    outline-color: rgba(231, 204, 151, 0.50);
    outline-style: solid

}
.passwordInput{
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.15px;

}

.passwordInput::placeholder{
    color: var(--neutral-shade-dark-0440, rgba(111, 118, 126, 0.40));
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.15px;
}
.captcha{
    color: var(--neutral-shade-dark-0450, #6F767E);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    margin-top: 20px;
    margin-bottom: 38px;
}
.register{
    color: var(--Neutral-01, #FCFCFC);

    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.14px; 
    text-decoration: none;
}



.continue-button{
    position: flex;
    display: inline-block;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.15px;
    width: 374px;
    height: 48px;
    border-radius: 12px;
    background: rgba(231, 204, 151, 0.50);
    color: rgba(252, 252, 252, 0.75);
    outline:none;
    border: none;
    margin-bottom: 16px;
    cursor: pointer;
    text-decoration: none;
}
.continue-button a{
    position: absolute;
    text-align: center;

}


.register-question{
    
    color: var(--Neutral-04, #6F767E);
    
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
    letter-spacing: -0.14px;
}
.imagenlogin{
    display: none;
    margin-bottom: 61px;
}
@media (max-width: 470px) {
    .imagen-login-div { 
        display: none;
    }
    .general{
        width: 420px;
        height: 812px;
        display: flex;
        justify-content: center;
    }
    .imagenlogin{
        display: block;   
    }

}