*{
    margin:0px;
    padding:0px;
    
}
body{
    background-color: #101010;
}

@media screen and (min-width: 768px) {
.general {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin: auto;
    width: 1440px;
    height: 1024px;
    background-color: #101010;
    }
    .padre {
        display: grid;
        grid-template-columns: 312px 1128px;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        }
    .imagenpanel{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 35px;
        margin-bottom: 37px;
    }
    .imagenhome{
        display: none;
    }
    .imagenmenu{
        display: none;
    }
    .Titdashb{
        color: #FFF;
        font-family: 'Inter', sans-serif;
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 70.588% */
        letter-spacing: -0.34px;
        }
    .Subtdash{
        color: #616161;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.16px;
        margin-top: 14px;
        }
    .Recuadromedio{
        border-radius: 18px;
        border: 1px solid #2E2E2E;
        background-color: #2E2E2E;
        width: 344px;
        height: 209px;
        flex-shrink: 0;
        margin-right: 24px;
        position: absolute;
        }
    .Recuadromedio2{
        border-radius: 18px;
        border: 1px solid #2E2E2E;
        background-color: #2E2E2E;
        width: 344px;
        height: 209px;
        flex-shrink: 0;
        margin-right: 24px;
        position: absolute;
        left: 368px;
            }
            
    .Recuadromedio3{
         border-radius: 18px;
         border: 1px solid #2E2E2E;
         background-color: #2E2E2E;
         width: 344px;
         height: 402px;
         flex-shrink: 0;
         margin-right: 24px;     
         float:right;
             }
    .Recuadromedio4{
        border-radius: 18px;
        border: 1px solid #2E2E2E;
        background-color: #2E2E2E;
        width: 160px;
        height: 173.72093px;
        flex-shrink: 0;
        margin-top: 233px;
        position: absolute;
            }
         
    .Recuadromedio5{
    border-radius: 18px;
    border: 1px solid #2E2E2E;
    background-color: #2E2E2E;
    width: 344px;
    height: 169px;
    flex-shrink: 0;
    margin-top: 235px;
    position: absolute;
    left: 368px;
        } 
    .Recuadromedio6{
    border-radius: 18px;
    border: 1px solid #2E2E2E;
    background-color: #2E2E2E;
    width: 160px;
    height: 173.72093px;
    flex-shrink: 0;
    margin-top: 233px;
    position: absolute;
    left: 184px;
    }
    .div4dashboard { grid-area: 2 / 2 / 3 / 3; }
    .div5dashboard { grid-area: 3 / 1 / 4 / 2; margin-left: 24px;}
    .div6dashboard { grid-area: 3 / 2 / 4 / 3; }
    .partederecha {
        float: left;
    }
    .titu3{
        display: flex;
        width: 500px;
        align-items: center;
        margin-left: 14px;
        margin-top: 14px;
        margin-bottom: 22px;
        justify-content: space-between;
    }
    .titu34{
        display: flex;
        width: 500px;
        align-items: center;
        margin-left: 14px;
        margin-top: 14px;
        margin-bottom: 18px;
        justify-content: space-between;
    }
    .tiempo{
        width: 127px;
        height: 40px;
        display: flex;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 12px;
        
        border: 1px solid #616161;
        }
    .tarjetamyteam2{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        width: 684px;
        height: 104px;
        }
        .devider hr{
            margin-left:24px ;
            width: 264px;
            height: 2px;
            border-radius: 2px;
            background: #262626;
            border-style: none;
        }
        .titu345{
            display: flex;
            width: 370px;
            align-items: center;
            margin-left: 14px;
            margin-top: 14px;
            margin-bottom: 18px;
            justify-content: space-between;
        }
}
    .div1index { 
        grid-area: 1 / 1 / 2 / 2;
        position: relative;
    }
    .div2index { 
        grid-area: 1 / 2 / 2 / 3;
        position: relative; 
    }



.imagee{
    position: absolute;
    inset: 0;
    margin: auto;
    border-radius: 42px;
}

.login{
    width: 374px;
    height: 394px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
}

.titulo{
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.46px;
    margin-bottom: 30px;
}

.caja1{
    height: 48px;
    border-radius: 12px;
    background: #1B1B1B;
    margin-bottom: 22px;
}

.caja2{
    height: 48px;
    border-radius: 12px;
    background: #1B1B1B;
    margin-bottom: 22px;
}

.captcha{
    color: var(--neutral-shade-dark-0450, #6F767E);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    margin-bottom: 38px;
}



button{
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 160% */
    letter-spacing: -0.15px;
    width: 374px;
    height: 48px;
    border-radius: 12px;
    background: rgba(231, 204, 151, 0.50);
    color: rgba(252, 252, 252, 0.75);
    outline:none;
    border: none;
    margin-bottom: 16px;
}

.forget{
    color: var(--Neutral-04, #6F767E);
text-align: center;

/* Body 2 (Semibold) */
font-family: 'Inter', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: -0.14px;
}


    
    
    @media screen and (min-width: 768px) {
    .analytics { 
        grid-area: 1 / 2 / 2 / 3; 
        background-color: #101010;;
    }
    .panel { 
        grid-area: 1 / 1 / 2 / 2; 
        background-color:#1B1B1B;
        justify-content: center;
        align-items: center;
    
    }

    .analytics {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 126px 426px 448px;
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        }
    .Recuadrobajo{
        border-radius: 18px;
        border: 1px solid #2E2E2E;
        background-color: #2E2E2E;
        width: 528px;
        height: 448px;
        flex-shrink: 0;
        margin-right: 24px;
        }
        .Recuadrobajo2{
            border-radius: 18px;
            border: 1px solid #2E2E2E;
            background-color: #2E2E2E;
            width: 528px;
            height: 448px;
            flex-shrink: 0;
            margin-right: 24px;
            }
            .Recuadrito1{
                border-radius: 18px;
                border: 1px solid #2E2E2E;
                background-color: #2E2E2E;
                width: 712px;
                height: 413px;
                flex-shrink: 0;
                margin-right: 24px;
                margin-left: 24px;
                position: absolute;
            }
            .Recuadrito2{
                border-radius: 18px;
                border: 1px solid #2E2E2E;
                background-color: #2E2E2E;
                width: 344px;
                height: 413px;
                flex-shrink: 0;
                margin-right: 24px;
                margin-left: 24px;
                position: absolute;
            }
            .Recuadrito3{
                border-radius: 18px;
                border: 1px solid #2E2E2E;
                background-color: #2E2E2E;
                width: 1080px;
                /* height: 687px; */
                flex-shrink: 0;
                margin-right: 24px;
                position: absolute;
                margin-left: 24px;
            }
            .div2myteam { grid-area: 2 / 1 / 3 / 2; }
            .div3myteam { grid-area: 2 / 2 / 3 / 3; }
            .div4myteam { grid-area: 3 / 1 / 4 / 3; } 
        .tarjetamyteam{
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 16px;
            width: 684px;
            height: 104px;
        } 
        .devider3 hr{
            margin-left: 14px;
            width: 670px;
            height: 2px;
            border-radius: 2px;
            background: #262626;
            border-style: none;
        }
        .div2analytics { grid-area: 1 / 3 / 2 / 4; }
        .div3analytics { grid-area: 2 / 1 / 3 / 2; }
        .div4analytics { grid-area: 2 / 2 / 3 / 4; }
        .div5analytics { grid-area: 3 / 1 / 4 / 3; }
        .div6analytics { grid-area: 3 / 3 / 4 / 4; }
        .div7analytics { grid-area: 4 / 1 / 5 / 4; }
        
        .Recuadro1{
            border-radius: 18px;
            border: 1px solid #2E2E2E;
            background-color: #2E2E2E;
            width: 528px;
            height: 189px;
            flex-shrink: 0;
            margin-right: 24px;
            margin-left: 24px;
            position: absolute;
                }
            
        .Recuadro2{
            border-radius: 18px;
            border: 1px solid #2E2E2E;
            background-color: #2E2E2E;
            width: 528px;
            height: 189px;
            flex-shrink: 0;
            margin-right: 24px;
            position: absolute;
            margin-left: 24px;
            }
        
        .Recuadro3{
            border-radius: 18px;
            border: 1px solid #2E2E2E;
            background-color: #2E2E2E;
            width: 712px;
            height: 448px;
            flex-shrink: 0;
            margin-right: 24px;
            margin-left: 24px;
            position: absolute;

            }
        .Recuadro4{
            border-radius: 18px;
            border: 1px solid #2E2E2E;
            background-color: #2E2E2E;
            width: 344px;
            height: 448px;
            flex-shrink: 0;
            margin-right: 24px;
            position: absolute;
            margin-left: 24px;   
            }
        
        
        .padreAnalytics {
            display: grid;
            grid-template-columns: 312px 1128px;
            grid-template-rows: 2000px;
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            }
        
        .Recuadro5{
            border-radius: 18px;
            border: 1px solid #2E2E2E;
            background-color: #2E2E2E;
            width: 1080px;
            height: 506px;
            flex-shrink: 0;               
            position: absolute;
            margin-left: 24px;   
            }
        .Recuadro56{
           border-radius: 18px;
           border: 1px solid #2E2E2E;
           background-color: #2E2E2E;
           width: 1080px;
           /* height: 506px; */
           flex-shrink: 0;               
           position: absolute;
           margin-left: 24px; 
           padding: 8px;  
           }
           .TrafficChanel-header{
            display: flex;
            width: 675px;
            align-items: center;
            margin-left: 14px;
            margin-top: 14px;
            margin-bottom: 22px;
            justify-content: space-between;
        }
        .div2leaderboard { grid-area: 2 / 1 / 3 / 2; 
            margin-left: 24px;
            justify-content: center; 
            align-items: center; 
            display: flex;
        }
        
        .div3leaderboard { grid-area: 3 / 1 / 4 / 2; }
        .Recuadrinho1{
            border-radius: 18px;
            border: 1px solid #2E2E2E;
            background-color: #2E2E2E;
            width: 1080px;
            /* height: 687px; */
            flex-shrink: 0;
            margin-right: 24px;
            margin-left: 24px;
            position: absolute;
            }
    }
        
        .div1dashboard { grid-area: 1 / 1 / 2 / 2; margin-left: 24px; margin-top: 38px;}
        .div2dashboard { grid-area: 1 / 2 / 2 / 3; }
        .div3dashboard { grid-area: 2 / 1 / 3 / 2; margin-left: 24px; position:relative}

.parent {
        display: grid;
        grid-template-columns: 552px 184px 344px;
        grid-template-rows: 126px 213px 472px 1000px;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        }
                        
.div1analytics { grid-area: 1 / 1 / 2 / 2; }




.parido {
    display: grid;
    grid-template-columns: 430px 344px;
    grid-template-rows: 124px 437px 800px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
                    
.div1myteam { grid-area: 1 / 1 / 2 / 3; }
    
                    


.parentLeaderboard {
    display: grid;
    grid-template-columns: 1080px;
    grid-template-rows: 110px 400px 800px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    
.div1leaderboard { grid-area: 1 / 1 / 2 / 2; }


.hijos{
    display: grid;
    grid-template-columns: repeat(3, 237px);
    grid-template-rows: 366px;
    width: 712px;
    height: 366px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    justify-content: center;
}
    .divuno { grid-area: 1 / 1 / 2 / 2; position: relative;}
    .divdos { grid-area: 1 / 2 / 2 / 3; position: relative;margin-left: 1px;}
    .divtres { grid-area: 1 / 3 / 2 / 4; position: relative;margin-left: 1.5px;}

.rect1{
    width: 237px;
    height: 185px;
    flex-shrink: 0;
    background: linear-gradient(116deg, #6A4822 -44.9%, #EBCC92 4.2%, #DECBA5 91.9%, #7F5D33 130.48%);
    bottom: 0;
    position: absolute;
}

.rect2{
    width: 237px;
    height: 275px;
    flex-shrink: 0;
    background: linear-gradient(116deg, #6A4822 -44.9%, #EBCC92 4.2%, #DECBA5 91.9%, #7F5D33 130.48%);
    bottom: 0;
    position: absolute;
}

.rect3{
    width: 237px;
    height: 185px;
    flex-shrink: 0;
    background: linear-gradient(116deg, #6A4822 -44.9%, #EBCC92 4.2%, #DECBA5 91.9%, #7F5D33 130.48%);
    bottom: 0;
    position: absolute;
}




.rect1 img{
    width: 66px; /* Ancho de la imagen */
            height: 66px; /* Altura de la imagen */
            object-fit: cover; /* La imagen cubre el contenedor, manteniendo las proporciones */
            position: absolute;
            top: -33px; /* Ajusta este valor para que la mitad de la imagen esté fuera del rectángulo */
            left: 50%;
            transform: translateX(-50%);
            border-radius: 50%; /* Borde redondeado para que la imagen sea un círculo */
        }

.rect2 img{
    width: 66px; /* Ancho de la imagen */
    height: 66px; /* Altura de la imagen */
    object-fit: cover; /* La imagen cubre el contenedor, manteniendo las proporciones */
    position: absolute;
    top: -33px; /* Ajusta este valor para que la mitad de la imagen esté fuera del rectángulo */
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%; /* Borde redondeado para que la imagen sea un círculo */
}   

.rect3 img{
    width: 66px; /* Ancho de la imagen */
    height: 66px; /* Altura de la imagen */
    object-fit: cover; /* La imagen cubre el contenedor, manteniendo las proporciones */
    position: absolute;
    top: -33px; /* Ajusta este valor para que la mitad de la imagen esté fuera del rectángulo */
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%; /* Borde redondeado para que la imagen sea un círculo */
}    



.botonperfil{
    border-radius: 8px;
    background:#222121;;
    width: 264px;
    height: 58px;
    margin-left: 24px;
    margin-bottom: 36px;
    display: flex;
    align-items: center;
}

.titulinho{
    margin-left: 24px;
    margin-bottom: 12px;

}

.titulinho h1{
color: #FFF;
font-family: 'Inter', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
letter-spacing: -0.16px;
}

.lista{
    border-radius: 8px;
    background:#222121;;
    width: 264px;
    height: 48px;
    margin-left: 24px;
    margin-bottom: 12px;
    display: flex;
    width: 264px;
    align-items:center;
    outline: none;
    
}
.botones-sidebar{
 text-decoration: none;
}



.divcon2opc{
    margin-bottom: 16px;
}
        

.lista h1{
    color: #585858;

/* Base 1 (Semibold) */
font-family: 'Inter', sans-serif;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 160% */
letter-spacing: -0.15px;

}


.lista img{
    margin-left: 12px;
    margin-right: 14px;
}

.listaactive{
    border-radius: 8px;
    border-radius: 12px;
    background: linear-gradient(90deg, #6A4822 -432.14%, #EBCC92 -85.7%, #DECBA5 532.95%, #7F5D33 805.15%);
    width: 264px;
    height: 48px;
    margin-left: 24px;
    margin-bottom: 12px;
    display: flex;
    width: 264px;
    align-items:center;
}

.listaactive h1{
    color: #000;

/* Base 1 (Semibold) */
font-family: 'Inter', sans-serif;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 160% */
letter-spacing: -0.15px;
}


.listaactive img{
    margin-left: 12px;
    margin-right: 14px;
}


.botonperfil h1{
color: #FFF;
/* Base 1 (Semibold) */
color: #FFF;
font-family: 'IBM Plex Sans', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 100% */
}


.botonperfil img{
    margin-left: 12px;
    margin-right: 14px;
}

.rectarriba {
    width: 66px; /* Ajusta el ancho según tus necesidades */
    height: 44px; /* Ajusta la altura según tus necesidades */
    border-radius: 12px;
    border: 1px solid #2E2E2E;
    background: #1B1B1B;
    position: absolute;
    top: -95px; /* Ajusta este valor para que la mitad de la imagen esté fuera del rectángulo */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.posicion{
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px; 
}




.titu h1{
    margin-left: 12px;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 109.091% */
    letter-spacing: -0.22px;
}

.numerodentro h1{
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.44px;
}

.numerodentro{
    margin-left: 14px;
    margin-bottom: 22px;
}

.numerobajo{
    margin-left: 14px;
    margin-bottom: 22px;
    display: flex;
    padding: 4px;
    align-items: center;
    gap: 4px;
}
.numerobajo img{
    width: 17px;
    height: 17px;
}

.porcentaje{
color: #83BF6E;
font-family: 'Inter', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 16px; /* 100% */
letter-spacing: -0.16px;
}

.textito{
color: #6F767E;
font-family: 'Inter', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 16px;
letter-spacing: -0.16px;
}



.totalsales{
    display: flex;
    align-items: center;
}

.titu2{
    display: flex;
    width: 316px;
    align-items: center;
    margin-left: 14px;
    margin-top: 14px;
    margin-bottom: 22px;
    
}

.titu2 h1{
    color: #FFF;
    font-family: 'Inter', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: -0.14px;
margin-left: 4px;
}

.numerodentro2 h1{
    color: #FFF;
    font-family: 'Inter', sans-serif;
font-size: 36px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.36px;
}

.numerodentro2{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 22px;
}

.porcentaje2{
    color: #B83232;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 100% */
    letter-spacing: -0.16px;
    }





.temporal{
    margin-left: 12px;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 109.091% */
    letter-spacing: -0.22px;
}

.h1titu3{
    margin-left: 12px;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 109.091% */
    letter-spacing: -0.22px;
}


.tarjeta{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;
}

.tarjeta h1{
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.18px;
    margin-bottom: 6px;
}


.fotoperfil{
    margin-left: 14px;
}

.tarjetamyteam h1{
    color: #FFF;
    font-family: 'Inter', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 133.333% */
letter-spacing: -0.18px;
margin-bottom: 6px;
}

.numero-diferente{
    color: #E7CC97;
    font-family: 'Inter', sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.12px;
margin-right: 2px;
}
.numero-diferente2{
    color: #B83232;
    font-family: 'Inter', sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.12px;
margin-right: 2px;
}

.fecha{
    color: #FFF;
    font-family: 'Inter', sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.12px;
display: flex;
align-items: center;
margin-right: 6px;
}

.datos{
    display: flex;
    align-items: center;
}

.fecha svg{
    margin-right: 2px;
}

.imgfecha{
    margin-right: 2px;
}

.devider2 hr{
    margin-left:14px ;
    width: 610px;
    height: 2px;
    border-radius: 2px;
    background: #262626;
    border-style: none;
}

.tarjcondiv{
    margin-bottom: 22px;
}

.numaltarj{
    color: #E7CC97;
text-align: right;
font-family: 'Inter', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 133.333% */
letter-spacing: -0.18px;
}

.seemore{
    width: 97px;
    height: 28px;
    display: flex;
padding: 2px 8px;
justify-content: center;
align-items: center;
gap: 2px;
border-radius: 12px;
border: 1px solid #616161;
}

.seemore h1{
    color: #FFF;
text-align: right;
font-family: 'Inter', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: -0.14px;
}

.padreseemore{
    justify-content: center;
align-items: center;
display: flex;

}



.h1titu34{
    margin-left: 12px;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 109.091% */
    letter-spacing: -0.22px;
}

.h1titu345{
    margin-left: 12px;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 109.091% */
    letter-spacing: -0.22px;
}

.titulardo1{
    width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}        
.caja-group{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;
    width: 684px;
    height: 104px;
}

.viewdetails{
    width: 137px;
    height: 40px;
    display: flex;
padding: 8px 12px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 12px;
}

.fotoytexto{
    
    width: 232px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.fotoperfil1{
    margin-left: 12px;
   margin-right: 12px;
}



.tarjetamyteam2 h1{
    color: #FFF;
    font-family: 'Inter', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 133.333% */
letter-spacing: -0.18px;

}


.temporal2{
color: #FFF;
/* Base 2 */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: -0.14px;
}

.h1titulardaso{
    margin-bottom: 6px;
}

.confirm h1{
    color: #000;

/* Base 2 */
font-family: 'Inter', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: -0.14px;
}

.padreseemore1{
    justify-content: center;
align-items: center;
display: flex;
}

.seemore1{
    width: 97px;
    height: 28px;
    display: flex;
padding: 2px 8px;
justify-content: center;
align-items: center;
gap: 2px;
border-radius: 12px;
border: 1px solid #616161;
}

.seemore1 h1{
    color: #FFF;
text-align: right;
font-family: 'Inter', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: -0.14px;
}

.tarjcondiv8{
    margin-bottom: 17px;
}


.tiempo2{
    width: 127px;
    height: 40px;
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    
    border: 1px solid #616161;
    margin-right: 18px;
    }
    .titu35{
        display: flex;
        align-items: center;
        margin-left: 14px;
        margin-top: 14px;
        margin-bottom: 22px;
        justify-content: space-between;
    }
/* Desde aca */ 

.TrafficChanel-header-topcountry{
    display: flex;
    width: 675px;
    align-items: center;
    margin-left: 14px;
    margin-top: 25px;
    margin-bottom: 22px;
    justify-content: space-between;
}
.TrafficChanel-header-h1{
    margin-left: 12px;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 109.091% */
    letter-spacing: -0.22px;
}

.TrafficChanel-header-img{
    width: 16px;
    height: 32px;
    border-radius: 4px;
    background: #E8CC97;
}


.partederecha2-boton{

    height: 40px;
    display: flex;

    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    
    border: 1px solid #616161;
}

.temporal{
    margin-left: 12px;
    color: #6F767E;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 109.091% */
    letter-spacing: -0.22px;
}

.LeaderboardList-header{
    display: flex;
    width: 1050px;
    align-items: center;
    margin-left: 14px;
    margin-top: 14px;
    margin-bottom: 22px;
    justify-content: space-between;
}

.LeaderboardList-header-h1{
    margin-left: 12px;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 109.091% */
    letter-spacing: -0.22px;
}

.LeaderboardList-header-img{
    width: 16px;
    height: 32px;
    border-radius: 4px;
    background: #E8CC97;
}

.lb-partederecha2-boton{
    width: 127px;
    height: 40px;
    display: flex;

    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    
    border: 1px solid #616161;
}

.tabla-posicion{
    text-align: left;
    color: #83BF6E;
text-align: left;
font-family: 'Inter', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 133.333% */
letter-spacing: -0.18px;
width: 43px;
}

.tabla-Nombre{
    color: #FFF;
    font-family: 'Inter', sans-serif;
font-size: 14px;
text-align: left;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
letter-spacing: -0.14px;

}

.tabla-totalventas{
    text-align: left;
    color: #83BF6E;

font-family: 'Inter', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 133.333% */
letter-spacing: -0.18px;
width: 43px;
}

.tabla-ventaspendientes{
    text-align: left;
    color: #B83232;

font-family: 'Inter', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 133.333% */
letter-spacing: -0.18px;
width: 43px;
}
.tabla-recuentodeventas{
    text-align: left;
    color: #FFFFFF;

font-family: 'Inter', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 133.333% */
letter-spacing: -0.18px;
width: 43px;
}

.tabla-total{
    text-align: left;
    color: #FFFFFF;

font-family: 'Inter', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 133.333% */
letter-spacing: -0.18px;
width: 43px;
}

.Titulo-nombre{
    color: #4F4F4F;
    font-family: 'Inter', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
letter-spacing: -0.14px;
text-align: left;
width: 30%;
}

.Titulo-ventas{
    color: #4F4F4F;
    font-family: 'Inter', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
letter-spacing: -0.14px;
    text-align: left;
    width: 21.66%;
}

.Titulo-rank{
color: #4F4F4F;
font-family: 'Inter', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
letter-spacing: -0.14px;
text-align: left;
margin-right: 20px;
width: 5%;
}

.Titulo-ventaspendientes{
color: #4F4F4F;
font-family: 'Inter', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
letter-spacing: -0.14px;
text-align: left;
width:21.66%;
}

.Titulo-total{
color: #4F4F4F;
font-family: 'Inter', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
letter-spacing: -0.14px;
text-align: left;
width:21.66%;
}


.tabla{
    margin-left: 22px;
    width: 96%;
    border-collapse: collapse;
}

th,td{
    width: 20%;
    border-bottom: 1px solid #4F4F4F ;
}

td{
    height: 60px;
    justify-content: center;
}

th{
    padding-bottom: 6px;
}

.tabla-nombrefoto img{
    margin-right: 8px;
    vertical-align: middle
}

.Titulo-nombre-activity{
    width: 40%;
    color: #4F4F4F;
font-family: 'Inter', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
letter-spacing: -0.14px;
text-align: left;
}

.Titulo-ventas-activity{
    width: 20%;
    color: #4F4F4F;
font-family: 'Inter', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
letter-spacing: -0.14px;
text-align: left;
}

.Titulo-ventaspendientes-activity{
    width: 20%;
    color: #4F4F4F;
font-family: 'Inter', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
letter-spacing: -0.14px;
text-align: left;
}

.Titulo-total-activity{
    width: 20%;
    color: #4F4F4F;
font-family: 'Inter', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
letter-spacing: -0.14px;
text-align: left;
}

@media (max-width: 470px) {
    .padre{
        display: grid;
        height: 82px;
        width: 100%;
        grid-template-columns: auto;
        background-color: #1B1B1B;
    }
    .panel{
        height: 82px;
    }

    .panel-res {
        height: 100vh;
        background-color: #1B1B1B;
        display: flex;
        width: 100vw;
        justify-content: center;
    }

    .imagenpanel{
        display: none;
    }
    .panel2 {
        display: none;
    }

    .panel2-res {
        display: unset;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
    }


    .imagenhome{
        position: absolute;
        width: 46px;
        height: 48px;
        margin-top: 14px;
        margin-left: 24px;
    }

    .imagenHome-res{
        width: 46px;
        height: 48px;
        margin-top: 14px;
    }

    .imagenmenu{
        position: absolute;
        width: 42px;
        height: 42px;
        margin-top: 20px;
        margin-left: 75%;
    }

    .imagenmenu-res {
        position: absolute;
        width: 42px;
        height: 42px;
        margin-top: 20px;
        margin-left: 60%;
    }

    .Titdashb{
        color: #FFF;
        font-family: 'Inter', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 70.588% */
        letter-spacing: -0.34px;
        }
    .Subtdash{
        color: #616161;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 150% */
        letter-spacing: -0.16px;
        margin-top: 10px;
        margin-bottom: 24px;
        }
    .Recuadromedio{
        border-radius: 18px;
        border: 1px solid #2E2E2E;
        background-color: #2E2E2E;
        width: 327px;
        height: 209px;
        flex-shrink: 0;
        margin-right: 24px;
        position: absolute;
        }
    .Recuadromedio2{
        border-radius: 18px;
        border: 1px solid #2E2E2E;
        background-color: #2E2E2E;
        width: 327px;
        height: 209px;
        flex-shrink: 0;
        margin-right: 24px;
        position: absolute;
        margin-top: 225px;
    }
    .Recuadromedio3{
        display: none;
    }
    .Recuadromedio4{
        border-radius: 18px;
        border: 1px solid #2E2E2E;
        background-color: #2E2E2E;
        width: 158px;
        height: 160px;
        flex-shrink: 0;
        margin-top: 450px;
        position: absolute;
    }
    .Recuadromedio5{
        border-radius: 18px;
        border: 1px solid #2E2E2E;
        background-color: #2E2E2E;
        width: 327px;
        height: 169px;
        flex-shrink: 0;
        margin-top: 625px;
        position: absolute;
        
        }
    .Recuadromedio6{
        border-radius: 18px;
        border: 1px solid #2E2E2E;
        background-color: #2E2E2E;
        width: 158px;
        height: 160px;
        flex-shrink: 0;
        margin-top: 450px;
        position: absolute;
        left: 170px;
        }
    .Recuadrobajo{
        position: absolute;
        border-radius: 18px;
        border: 1px solid #2E2E2E;
        background-color: #2E2E2E;
        width: 327px;
        height: 228px;
        flex-shrink: 0;
        margin-top: 1050px;
        left: 24px;
        }
    .partederecha {
        position: relative;
    }
    .titu{
        display: flex;
        width: 304px;
        align-items: center;
        margin-left: 14px;
        margin-top: 14px;
        margin-bottom: 22px;
        justify-content: space-between;
    }
    .titu3{
        display: flex;
        align-items: center;
        margin-left: 14px;
        margin-top: 14px;
        margin-bottom: 22px;
        justify-content: space-between;
    }
    .tiempo{
        margin-right: 8px;
        width: 110px;
        height: 40px;
        display: flex;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 12px;
        
        border: 1px solid #616161;
        }
        .Recuadrobajo2{
            position: absolute;
            border-radius: 18px;
            border: 1px solid #2E2E2E;
            background-color: #2E2E2E;
            width: 327px;
            height: 228px;
            flex-shrink: 0;
            margin-top: 805px;
            left: 24px;
            }
            .Recuadrito1{
                border-radius: 18px;
                border: 1px solid #2E2E2E;
                background-color: #2E2E2E;
                width: 327px;
                height: 309px;
                flex-shrink: 0;
                margin-right: 24px;
                margin-left: 24px;
                position: absolute;
            }
            .Recuadrito2{
                border-radius: 18px;
                border: 1px solid #2E2E2E;
                background-color: #2E2E2E;
                width: 327px;
                height: 413px;
                flex-shrink: 0;
                margin-right: 24px;
                margin-left: 24px;
                position: absolute;
            }
            .Recuadrito3{
                
                border-radius: 18px;
                border: 1px solid #2E2E2E;
                background-color: #2E2E2E;
                width: 327px;
                flex-shrink: 0;
                margin-right: 24px;
                margin-left: 24px;
            }
        .div2myteam { 
            position: absolute;
            top: 255px;
         }
        .div3myteam { 
            position: absolute;
            top:875px; }
        .div4myteam { 
            position: absolute;
            top:520px;
         }
        .tarjetamyteam{
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            height: 104px;
        } 
        .tarjetamyteam2{
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            height: 104px;
        } 
        .devider hr{
            margin-left:24px ;
            margin-right: 24px;
            height: 2px;
            border-radius: 2px;
            background: #262626;
            border-style: none;
        }
        .devider3 hr{
            margin-left: 14px;
            margin-right: 24px;
            height: 2px;
            border-radius: 2px;
            background: #262626;
            border-style: none;
        }
        .titu345{
            display: flex;
            align-items: center;
            margin-left: 14px;
            margin-top: 14px;
            margin-bottom: 18px;
            justify-content: space-between;
        }
        .div3analytics { position: absolute; 
            top: 200px; }
        .div4analytics { 
            position: absolute; 
            top: 400px; 
        }
        
        .div5analytics { 
            position: absolute; 
            top: 600px;
         }
        .div6analytics {
            position: absolute; 
            top: 820px; 
        }
        .div7analytics { 
            display: none;
         }
        
        .Recuadro1{
            border-radius: 18px;
            border: 1px solid #2E2E2E;
            background-color: #2E2E2E;
            width: 327px;
            height: 189px;
            flex-shrink: 0;
            margin-right: 24px;
            margin-left: 24px;
            position: absolute;
                }
            
        .Recuadro2{
            border-radius: 18px;
            border: 1px solid #2E2E2E;
            background-color: #2E2E2E;
            width: 327px;
            height: 189px;
            flex-shrink: 0;
            margin-right: 24px;
            position: absolute;
            margin-left: 24px;
            }
        
        .Recuadro3{
            border-radius: 18px;
            border: 1px solid #2E2E2E;
            background-color: #2E2E2E;
            width: 327px;
            height: 207px;
            flex-shrink: 0;
            margin-right: 24px;
            margin-left: 24px;
            position: absolute;
                
            }
        .Recuadro4{
            border-radius: 18px;
            border: 1px solid #2E2E2E;
            background-color: #2E2E2E;
            width: 327px;
            height: 448px;
            flex-shrink: 0;
            margin-right: 24px;
            position: absolute;
            margin-left: 24px;   
            }
        
        
        .padreAnalytics {
            display: grid;
            grid-template-columns: 312px 1128px;
            grid-template-rows: 2000px;
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            }
        
        .Recuadro5{
            border-radius: 18px;
            border: 1px solid #2E2E2E;
            background-color: #2E2E2E;
            width: 1080px;
            height: 506px;
            flex-shrink: 0;               
            position: absolute;
            margin-left: 24px;   
            }
            .titu34{
                margin-right: 10px;
                display: flex;
                align-items: center;
                margin-left: 14px;
                margin-top: 14px;
                margin-bottom: 18px;
                justify-content: space-between;
            }
        .Recuadro56{
            border-radius: 18px;
            border: 1px solid #2E2E2E;
            background-color: #2E2E2E;
            width: 1080px;
            flex-shrink: 0;               
            position: absolute;
            margin-left: 24px; 
            padding: 8px;  
            }
            .TrafficChanel-header{
                display: flex;
                margin-right:15px;
                align-items: center;
                margin-left: 14px;
                margin-top: 14px;
                margin-bottom: 22px;
                justify-content: space-between;
            }
            .div2leaderboard { 
                position: absolute;
                top:270px;
                width: 75vw;
                margin-left: 24px;
                justify-content: center; 
                align-items: center; 
                display: flex;
            }
            
            .div3leaderboard { 
                position: absolute;
                top: 680px;
             }
    .Recuadrinho1{
       border-radius: 18px;
       border: 1px solid #2E2E2E;
       background-color: #2E2E2E;
       width: 327px;
       /* height: 687px; */
       flex-shrink: 0;
       margin-right: 24px;
       margin-left: 24px;
       position: absolute;
       }

    .hijos {
        grid-template-columns: repeat(3, 108px);
    }

       .rect1, .rect2, .rect3 {
        width: 108px;
    }
    
    .input-search-leaderboard, .search-icon {
        display: none;
    }

    .input-search-leaderboard, .Recuadrinho1 {
        width: 327px;
        overflow-x: auto;
        overflow-y: auto;
    }

    .tiempo2 {
        width: 82px;
    }

    .temporal {
        font-size: 12px;
        margin-left: unset;
    }

    .tabla-nombrefoto {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .parentLeaderboard {
        grid-template-columns: unset;
    }
}    
