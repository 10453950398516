.leaderboard-header-container {
    display: flex;
    flex-direction: column;
    margin: 40px;
}

.leader-board-title-header, .leader-board-subtitle-header {
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.46px;
}

.leader-board-title-header {
    font-size: 34px;
}
.leader-board-subtitle-header {
    color: #616161;
    font-weight: 400;
    font-size: 16px;
}

.input-search-leaderboard {
    background-color: #1B1B1B;
    border: 1px solid #616161;
    border-radius: 12px;
    padding: 16px;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.46px;
    padding-left: 45px;
}

.input-container {
    position: relative;
    margin-right: 5px;
}

.input-wrapper {
    position: relative;
    display: inline-block;
}

.search-icon {
    position: absolute;
    left: 8px; /* Ajusta este valor según sea necesario para la posición horizontal de la imagen */
    top: 50%; /* Ajusta este valor para centrar la imagen verticalmente en el input */
    transform: translateY(-50%);
    width: 20px; /* Ajusta este valor según el ancho de tu imagen */
    height: 20px; /* Ajusta este valor según la altura de tu imagen */
}

.no-results-label {
    color: #616161;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.46px;
    font-size: 32px;
}

@media (max-width: 470px) {
    .not-result-leaderboard-container {
        width: 327px !important;
    }
    .no-results-label {
        font-size: 16px;
    }
}