.div2myteam { 
    display: flex;
}

.Recuadrito1{
    border-radius: 18px;
    border: 1px solid #2E2E2E;
    background-color: #2E2E2E;
    width: 400px;
    height: 413px;
    flex-shrink: 0;
    margin-right: 24px;
    margin-left: 24px;
    position: absolute;
}

.Recuadrito2{
    border-radius: 18px;
    border: 1px solid #2E2E2E;
    background-color: #2E2E2E;
    width: 641px;
    height: 413px;
    flex-shrink: 0;
    margin-right: 24px;
    margin-left: 24px;
    position: absolute;
}

.myTeam-list-you-team-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.infoIcon {
    width: 50px;
    height: 50px;
}

.info-label {
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.16px;
    text-align: center;
    margin-top: 10px;
}

.titu{
    display: flex;
    width: 610px;
    align-items: center;
    margin-left: 14px;
    margin-top: 14px;
    margin-bottom: 22px;
    justify-content: space-between;
}

.btn-see-more{
    width: 100px ;
    background-color: transparent;
    border: 1px solid #EFEFEF;
}

.btn-see-more:hover{
    cursor: pointer;
}

.member-container {
    display: flex;
    flex-direction: column;
}

.member-wrapper{
    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;
    justify-content: space-between;
}

.member-wrapper h1{
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.18px;
}

.role-label {
    color: #9C9C9C;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.18px;
}

.revenue-label {
    color: #83BF6E;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.18px;
}

.confirm{
    display: flex;
    padding: 8px 16px;
    align-items: center;
    justify-content: center;
    gap: 6px;
    width: 86px;
    height: 40px;
    border-radius: 12px;
    background-color: #E7CC97;
}

.btn-confirm-resp {
    display: none;
}

.table-container {
    min-height: 365px;
    max-height: 250px; /* Set a maximum height for the table container */
    overflow-y: auto; /* Enable vertical scrolling */
}

.select-con-imagen {
    position: relative;
    display: flex;
    align-items: center; /* Centra verticalmente */
    justify-content: center; /* Centra horizontalmente */
    width: 127px;
    margin-right: 20px;
  }
  
  .imagen-select {
    position: absolute;
    top: 50%;
    left: 15px;
  }

  .select-con-imagen select {
    appearance: none; /* Remove default arrow in some browsers */
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    background-color: #2E2E2E;
    border: 1px solid #EFEFEF;
    padding: 5px; /* Ajusta el padding según tus necesidades */
    font-size: 16px; /* Ajusta el tamaño de la fuente según tus necesidades */
    padding-left: 45px; /* Ajusta el padding izquierdo según el ancho de la imagen */
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
}

.div1dashboard {
    margin-left: 24px;
    margin-top: 38px;
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 56vw;
}

.btn-my-team-side {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px;
    font-weight: 400;
    background-color: #1B1B1B;
    color: #fff;
    border: 1px solid #EFEFEF;
    border-radius: 12px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
    text-wrap: nowrap;
}

.buttons-container {
    display: flex;
    flex-direction: row;
}

.button-icon {
    width: 20px;
    height: auto;
    margin-right: 10px;
}

.header-team-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 57vw;
    max-width: 57vw;
}

.label-input {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #FFF;
    font-size: 20px;
    margin-top: 20px;
}

.modal-input {
    font-size: 16px; /* Ajusta el tamaño de la fuente según tus necesidades */
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    width: 80%;
    border-radius: 12px;
    background-color: #676767;
    border: 1px solid #2E2E2E;
    padding: 15px;
    margin-top: 10px;
    color: white;
}

.input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.btn-container {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.btn-modal {
    width: 150px;
    margin-left: 20px;
    cursor:pointer
}

.close {
    background-color: unset;
    border: 1px solid #2E2E2E;
}

.results-container {
    width: 80%;
    max-height: 150px;
    overflow-y: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-top: none;
    background-color: #676767;
    z-index: 1000;
    top: 10%; /* Posiciona el contenedor debajo del input */
    left: 0; /* Alinea el contenedor a la izquierda del input */
  }
  
  .results-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .results-container li {
    padding: 13px;
    cursor: pointer;
    transition: background-color 0.3s;
    color: #FFF;
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    border-bottom: 1px solid black;
    letter-spacing: -0.14px;
  }
  
  .results-container li:hover {
    background-color: #2E2E2E;
  }

  .error-label {
    color: red;
    margin-top: 20px;
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
  }

.btn-confirm:hover {
    background-color: #E7CC97;
    color: #1B1B1B;
    font-weight: 600;
}

.ascend-title {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    color: white;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
}

.btn-confirm-container {
    display: flex;
    flex-direction: row;
}

.btn-confirm-container button {
    margin: 20px;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
}

.btn-ascend-confirm {
    background-color: transparent;
    border: 1px solid #676767;
}

.btn-ascend-confirm:hover {
    background-color: #E7CC97;
    color: black;
    font-weight: 600;
}

.leader-case, .employee-case {
    border: 1px solid #676767;
    background-color: transparent;
    width: 145px;
    height: 44px;
    font-family: 'Inter', sans-serif;
    margin: 20px;
    vertical-align: middle;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: white;
}

.employee-case {
    background-color: #E7CC97;
    color: #1B1B1B;
    font-weight: 600;
    cursor: pointer;
}

.employee-case-resp {
    display: none;
}

.leader-case-resp {
    display: none;
}

@media (max-width: 470px) {
    .analytics {
        width: 90vw;
    }
    .parido {
        width: 90vw;
        display: flex;
    }

    .Recuadrito1, .Recuadrito2, .Recuadrito3 {
        width: 90vw;
        margin: none;
    }

    .Recuadrito2 {
        overflow: auto;
    }
    
    .partederecha {
        margin-right: 15px;
    }

    .titu{
        width: 80vw;
    }

    .btn-confirm {
        display: none;
    }

    .btn-confirm-resp-container {
        width: 80%;
        display: flex;
        justify-content: center;
    }

    .member-container {
        align-items: center;
    }

    .devider2 hr{
        width: 80vw;
        margin-top: 15px;
    }

    .div3myteam {
        top: 700px;
    }
    .div4myteam {
        top: 1150px;
    }

    .table-container {
        min-height: 365px;
        max-height: 250px; /* Set a maximum height for the table container */
        overflow-y: auto; /* Enable vertical scrolling */
        max-width: 461px;
        overflow-x: auto; /* Enable horizontal scrolling */
    }

    .tabla {
        border-collapse: collapse;
    }

    .tabla th, .tabla td {
        padding-right: 30px; /* Ajusta el padding según tus necesidades */
        width: max-content;
        text-align: center;
    }

    .tabla th {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .header-team-container {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 24px;
    }

    .buttons-container {
        display: flex;
        flex-direction: column;
    }

    .div1dashboard {
        width: unset;
        margin-right: unset;
    }

    .modal-content content {
        width: 200px;
    }
 
    .ascend-title {
        margin-top: 50px;
    }

    .employee-case {
        display: none;
    }

    .leader-case-resp {
        display: flex;
        padding: 8px 60px;
        align-items: center;
        justify-content: center;

        background-color: transparent;
        border: 1px solid #EFEFEF;
        width: 80%;
        text-wrap: nowrap;
        color: #E7CC97;
        cursor: pointer;
        vertical-align: middle; /* Centra verticalmente el contenido del select */
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
        letter-spacing: -0.14px;
    }

    .employee-case-resp {
        display: flex;
        padding: 8px 60px;
        align-items: center;
        justify-content: center;

        background-color: transparent;
        border: 1px solid #EFEFEF;
        width: 80%;
        text-wrap: nowrap;
        color: #E7CC97;
        cursor: pointer;
        vertical-align: middle; /* Centra verticalmente el contenido del select */
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
        letter-spacing: -0.14px;
    }

    .leader-case {
        display: none;
    }
}