.delete-user-title {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #FFF;
    font-size: 34px;
}

.delete-user-subtitle {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #616161;
    font-size: 16px;
    margin-top: 10px;
}

.header-container-delete {
    display: flex;
    flex-direction: column;
    margin: 40px;
}

.table-container-delete {
    margin-left: 40px;
    background-color: #2E2E2E;
    height: 850px;
    border-radius: 18px;
    
}

.header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 40px;
}

.img-header {
    width: 24px;
    height: 24px;
    padding: 14px;
    background-color: #2A2A2A;
    border-radius: 100px;
}

.change-password-btn-table {
    background-color: transparent;
    border: 1px solid #616161;
    width: 150px;
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #FFF;
    font-size: 10px;
    cursor: pointer;
    margin: auto;
}

.delete-btn-table {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #FFF;
    background-color: #B83232;
    width: 150px;
    margin: auto;
    cursor: pointer;
    font-size: 10px;
}

.success-label-change {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    font-size: 20px;
    color: #83BF6E;
}

@media (max-width: 470px) {
    .delete-user-admin {
        top: unset;
    }

    .table-delete-container {
        width: 90vw;
        height: 720px;
    }

    .delete-content-modal {
        height: 200px !important;
    }

    .change-content-modal {
        height: 320px !important;
    }
}