.sales-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.validate-sales-container {
    display: flex;
    flex-direction: column;
}

.sales-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: #222222;
    width: 559px;
    height: 935px;
    border-radius: 18px;
    border: 1px solid #2E2E2E;

    overflow-y: auto;

    margin-top: 40px;
    margin-bottom: 40px;
}

.sales-title {
    color: #FFF;
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
}

.sales-subtitle {
    color: #616161;
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    margin-top: 10px;
    margin-left: 5px;
}

.links-input-container {
    display: flex;
    align-items: center;
}

.links-input {
    width: 421px !important;
}

.input-div-sales {
    margin-bottom: 30px;
}

.input-div-sales p {
    color: #FFF;
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
}

.input-div-sales input {
    margin-top: 5px;
    background-color: #1B1B1B;
    border: 1px solid #2E2E2E;
    border-radius: 12px;
    color: #FFF;
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    padding: 14px;
    width: 475px;
}

.custom-button {
    background-color: #E7CC97;
    color: #1B1B1B;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    margin: auto;
}

.select-platform, .select-payment-method {
    width: 500px;
    border: 1px solid #2E2E2E;
    margin: auto;
    margin-top: 5px;
    color: #FFF;
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    padding-left: 14px;
}

.custom-file-input {
    margin-top: 5px;
    width: 475px;
    height: 102px;
    position: relative;
}

.custom-file-input input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.custom-file-input label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 2px dashed #E7CC97;
    cursor: pointer;
}

.custom-file-input img {
    max-width: 50px;  /* Ajusta el tamaño de la imagen según sea necesario */
    max-height: 50px; /* Ajusta el tamaño de la imagen según sea necesario */
    margin-bottom: 8px; /* Espacio entre la imagen y el texto */
}

.custom-file-input span {
    text-align: center;
    color: rgb(111, 118, 126, .4);
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
}

.btn-continue-sales {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    background-color: #E7CC97;
    color: #1B1B1B;
    cursor: pointer;
}

.link-list-li {
    list-style: none;
    margin: auto;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.link-list-label {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #6F767E;
    width: 90%;
    margin-left: 5px;
}

.remove-link-btn {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: 1px solid #2E2E2E;
    cursor: pointer;
}

.remove-link-btn:hover {
    background-color: white;
    color: black;
}

.s {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    margin: 10px;
}

.e {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    margin-top: 0;
    margin-bottom: 10px;
}

.validate-sales-header-container {
    margin-top: 40px;
    margin-left: 40px;
}

.validate-sales-table-container {
    margin: 40px;
    background-color: #2E2E2E;
    border-radius: 18px;
    width: 75vw;
    height: 880px;
}

.table-header-container {
    display: flex;
    flex-direction: row;
}

.shop-fill {
    background-color: #2A2A2A;
    padding: 14px;
    border-radius: 100px;
}

.sales-title-table {
    margin-top: auto;
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    margin-bottom: 10px;
    color: #FFF;
}

.validate-sales-th, .validate-sales-td {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    margin-bottom: 10px;
    text-align: left;
    color: #4F4F4F;
}

.validate-sales-td {
    color: #FFF;
    padding: 10px;
    max-width: 150px;
    min-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}


.btn-table-sales {
    background-color: transparent;
    border: 1px solid #4F4F4F;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    cursor: pointer;
}

.btn-table-sales:hover {
    background-color: #E7CC97;
    color: #1B1B1B;
    font-weight: 600;
}

.btn-container-table-sales {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.confirm-sales-btn {
    cursor: pointer;
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
}

.sales-to-validate-box, .sales-validated-box {
    width: 528px;
    height: 209px;
    background-color: #2E2E2E;
    margin-left: 40px;
    border-radius: 18px;
}

.sales-box-container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.clock-img {
    padding: 14px;
    background-color: #2A2A2A;
    border-radius: 100px;
}

.check-img {
    padding: 7px;
    background-color: #2A2A2A;
    border-radius: 100px;
}

.validate-sales-box-subtitle {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #FFF;
    margin-left: 5px;
}

.total-sales-number {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #FFF;
}

@media (max-width: 470px) {
    .sales-container {
        margin-top: 40px;
    }
    .sales-form {
        height: 110vh;
    }

    .validate-sales-container {
        width: 90vw;
    }

    .sales-box-container {
        flex-direction: column;
    }

    .sales-to-validate-box, .sales-validated-box {
        width: 327px;
        margin-top: 20px;
    }
}