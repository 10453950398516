.header-container-dashboard {
    display: flex;
    justify-content: space-between;
    margin: 40px;
}

.header-title {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    font-size: 34px;
    color: #FFF;
}

.header-subtitle {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    font-size: 16px;
    color: #616161;
    margin-top: 5px;
}

.iconImageCreate {
    width: 22px;
    height: 22px;
}

.create-structure-button {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    font-size: 14px;
    color: #000000;
    background-color: #E7CC97;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 162px;
    cursor: pointer;
}

.container-analytic-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

.analytic-box {
    width: 344px;
    height: 209px;
    background-color: #1A1A1A;
    border-radius: 18px;
    border: 1px solid #2E2E2E;
    margin-bottom: 30px;
    margin-left: 30px;
}

.box-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
}

.box-img {
    padding: 10px;
    background-color: #2A2A2A;
    border-radius: 100px;
}

.box-title {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    font-size: 22px;
    color: #FFF;
    margin-left: 10px;
}

.box-data-container {
    margin-left: 20px;
    margin-top: 50px;
}

.box-data {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: bold;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    font-size: 44px;
    color: #FFF;
}

.box-trend-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    margin-top: 30px;
}

.box-trend-image {
    width: 22px;
    height: 22px;
}

.box-trend-label {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: bold;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    font-size: 16px;
    color: #83BF6E;
    margin-left: 5px;
}

.box-group-data-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.box-group-body {
    display: flex;
    flex-direction: column;
}

.box-group-img {
    width: 50px;
    height: 50px;
    margin-left: 10px;
}

.box-arrow-img {
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.box-group-name {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: bold;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    font-size: 19px;
    color: #FFF;
}

.box-group-quantity-number {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: bold;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    font-size: 12px;
    color: #E7CC97;
}

.box-group-quantity {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: bold;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    font-size: 12px;
    color: #FFF;
    margin-left: 3px;
}

.dashboard-graphs-container {
    display: flex;
    flex-direction: row;
}

.dashboard-graph-box {
    width: 528px;
    height: 448px;
    border-radius: 18px;
    background-color: #1A1A1A;
    border: 1px solid #2E2E2E;
    margin-left: 30px;
}

.modal-create-structure {
    overflow-y: auto;
    max-height: 484px;
}

.modal-input-container {
    display: flex;
    flex-direction: column;
    margin: 30px;
}

.label {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    font-size: 12px;
    color: #FFF;
    font-size: 15px;
}

.input-form {
    background-color: #1B1B1B;
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    font-size: 12px;
    color: #FFF;
    border: 1px solid #2E2E2E;
    border-radius: 12px;
    width: 475px;
    height: 48px;
    padding-left: 14px;
    margin-top: 8px;
}

input[type="checkbox"] {
    background-color: #1B1B1B !important; /* Cambia este valor al color que desees */
    /* Otros estilos opcionales */
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

.modal-checkbox-container {
    margin: 30px;
    display: flex;
    flex-direction: row;
}

.modal-button-container {
    display: flex;
    flex-direction: row;
    margin: 30px;
}

.modal-btn-cancel {
    background-color: transparent;
    border: 1px solid #2E2E2E;
    cursor: pointer;
    margin-right: 10px;
}

.modal-btn-cancel:hover {
    background-color: #E7CC97;
    color: #1A1A1A;
}

.modal-btn-confirm {
    background-color: #E7CC97;
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    font-size: 15px;
    color: #FFF;
    cursor: pointer;
}

.result-container {
    max-height: 200px;
    overflow-y: auto;
    background-color: #616161;
    margin-top: 3px;
}

.result-option {
    border-bottom: 1px solid #2A2A2A;
}

.label-result {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    font-size: 14px;
    color: #FFF;
    cursor: pointer;
    padding: 14px;
    text-align: center;
}

.mod-err {
    margin-left: 30px;
}

@media (max-width: 470px) {
    .header-container-dashboard {
        flex-direction: column;
        justify-content: unset;
        width: 360px;
    }

    .header-title {
        font-size: 24px;
    }

    .header-subtitle {
        font-size: 14px;
    }

    .create-structure-button {
        margin-top: 20px;
        width: 100%;
    }

    .container-analytic-boxes {
        flex-wrap: unset;
        justify-content: unset;
        flex-direction: column;
    }

    .dashboard-graphs-container {
        flex-direction: column;
    }

    .analytic-box {
        width: 377px;
    }
    
    .dashboard-graph-box {
        width: 377px;
        margin-bottom: 30px;
    }

    .title-graph {
        font-size: 14px;
    }

    .select-graph {
        font-size: 12px !important;
        height: unset !important;
    }

    .input-form {
        width: 327px;
    }

    .modal-create-structure {
        margin-top: 80px;
    }
}