.title-header-pm, .subtitle-header-pm {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #FFF;
}

.title-header-pm {
    font-size: 34px;
}

.subtitle-header-pm {
    color: #616161;
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
}

.box-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    width: 1200px;

}

.pm-box {
    background-color: #1B1B1B;
    border: 1px solid #2E2E2E;    
    width: 344px;
    height: 219px;
    border-radius: 18px;
    margin-left: 40px;
    margin-bottom: 40px;
}

.pm-img {
    width: 54px;
    height: 54px;
    border-radius: 8px;
}

.pm-label {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #FFF;
    font-size: 22px;
    margin-left: 10px;
}

.pm-total {
    vertical-align: middle; /* Centra verticalmente el contenido del select */
    font-family: 'Inter', sans-serif;
    text-wrap: nowrap;
    font-style: normal;
    font-weight: bold;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.14px;
    color: #FFF;
    font-size: 44px;
    margin-left: 10px;
}

@media (max-width: 470px) {
    .box-container {
        flex-direction: column;
    }
}